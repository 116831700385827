import React from 'react'
import { Link } from 'react-router-dom'
import '../menu.css'

export const Header = (): React.ReactElement => {
	return (
		<header className="header">
			<div className="container">
				<div className="row logo-row">
					<div className="logo">
						<Link to="/" className="homednb">
							<img className="logo-header" alt="Dun and Bradstreet Logo" />
						</Link>
						<p className="developer">D E V E L O P E R</p>
					</div>
				</div>
				<div className="row">
					<div className="menu-container">
						<nav>
							<header>
								<b>
									<Link to="/home" className="menu-link">
										Home
									</Link>
								</b>
							</header>
						</nav>
						<nav>
							<header>
								<b className="menu-link">Use Cases</b>
							</header>
							<div>
								<b>
									<Link to="/use-cases/All" className="menu-link">
										All
									</Link>
								</b>
							</div>
							<div>
								<b>
									<Link to="/use-cases/Finance" className="menu-link">
										Finance
									</Link>
								</b>
							</div>
							<div>
								<b>
									<Link to="/use-cases/Marketing" className="menu-link">
										Marketing
									</Link>
								</b>
							</div>
							<div>
								<b>
									<Link to="/use-cases/Sales" className="menu-link">
										Sales
									</Link>
								</b>
							</div>
							<div>
								<b>
									<Link to="/use-cases/Operations" className="menu-link">
										Operations
									</Link>
								</b>
							</div>
						</nav>
						<nav>
							<header>
								<b>
									{' '}
									<a
										href="https://directplus.documentation.dnb.com/"
										hrefLang="en"
										target="_blank"
										rel="noreferrer external"
										className="menu-link"
									>
										Documentation
									</a>
								</b>
							</header>
						</nav>						
						<nav>
							<header>
								<b>
									{' '}
									<a
										href="https://support.dnb.com/?prod=DNBDirect"
										hrefLang="en"
										target="_blank"
										rel="noreferrer external"
										className="menu-link"
									>
										Support
									</a>
								</b>
							</header>
						</nav>
					</div>
				</div>
			</div>

			<div className="container-small">
				<div className="row">
					<div className="menu-burguer">
						<input type="checkbox" id="menu" />
						<label htmlFor="menu">MENU</label>
						<ul>
							<li>
								<Link to="/home" className="menu-link">
									Home
								</Link>
							</li>
							<li>
								<Link to="/use-cases/All" className="menu-link">
									Use Cases
								</Link>
							</li>
							<li>
								<a
									href="https://directplus.documentation.dnb.com/"
									hrefLang="en"
									target="_blank"
									rel="noreferrer external"
									className="menu-link"
								>
									Direct+ API Documentation
								</a>
							</li>
							<li>
								<a
									href="https://docs.dnb.com/direct/2.0/en-US/quick-rest-API"
									hrefLang="en"
									target="_blank"
									rel="noreferrer external"
									className="menu-link"
								>
									Direct 2.0 Documentation
								</a>
							</li>
							<li>
								<a
									href="https://support.dnb.com/?prod=DNBDirect"
									hrefLang="en"
									target="_blank"
									rel="noreferrer external"
									className="menu-link"
								>
									Support
								</a>
							</li>
						</ul>
					</div>
					<div className="logo">
						<Link to="/" className="homednb">
							<img className="logo-header" alt="Dun and Bradstreet" />
						</Link>
						<p className="developer">D E V E L O P E R</p>
					</div>
					<div className="div-btn-reg">
						<Link to="/register" className="btn-register">
							Register
						</Link>
					</div>
				</div>
			</div>
		</header>
	)
}
