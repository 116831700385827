import React, { useEffect, useState } from 'react'
import { ExternalRegistrationForm } from '../components/external-registration-form'
import Modal from '../components/modal'

export const Register = (): React.ReactElement => {
	const [showModal, setShowModal] = useState(false)
	const [formsAvailable, setFormsAvailable] = useState(false)

	useEffect(() => {
		const script = document.createElement('script')
		const load_script = new Promise(function (resolve, reject) {
			script.src = 'https://info01.dnb.com/js/forms2/js/forms2.min.js'
			script.async = true
			script.addEventListener('load', function () {
				resolve('success')
			})
			script.addEventListener('error', function (e) {
				reject(e)
			})

			document.body.appendChild(script)
		})
		load_script.then(() => setFormsAvailable(true))
		return () => {
			document.body.removeChild(script)
		}
	}, [])

	return (
		<>
			<section className="head bg-register" id="head">
				<div className="container-body">
					<div className="container-title"></div>
				</div>
			</section>
			<section className="section-white">
				<div className="container-body">
					<div className="rack-regis-double">
						<div className="card-regis-double regis-left">
							<h2>Register for Paid Access</h2>
							<p className="space">
								To access any D&B APIs today, you need to first purchase an API key: <br/>fill in the form
								below to get that process started and find out pricing.
							</p>
							{formsAvailable && <ExternalRegistrationForm formId={1652} />}
						</div>						
					</div>
				</div>		
			</section>
			
			<Modal open={showModal} onClose={() => setShowModal(false)}>
				<h2>Register for Free Access</h2>
				{formsAvailable && <ExternalRegistrationForm formId={1832} />}
			</Modal>
		</>
	)
}
